import { Trans } from '@lingui/react';
import Button from '@components/buttons';
import { Box } from '@components/layout/Grid';
import {
  SubscriptionBox,
  SubscriptionBoxBilled,
  SubscriptionBoxPrice,
  SubscriptionBoxTitle,
} from '@components/templates/showcase/components';

const PricingTrialCard = ({ trialDays = 14, onPlanSelect }) => {
  return (
    <Box px={[0, 10, 10]} mb={[10, 10, 0]} className="SubscriptionBox--Card">
      <SubscriptionBox className="SubscriptionBox">
        <SubscriptionBoxTitle>
          <Trans
            id="pricing_section.plan.trial.title"
            values={{ days: trialDays }}
          />
        </SubscriptionBoxTitle>
        <SubscriptionBoxPrice>
          <Trans id="pricing_section.plan.trial.price" />
        </SubscriptionBoxPrice>
        <SubscriptionBoxBilled>
          <Trans id="pricing_section.plan.trial.body" />
        </SubscriptionBoxBilled>
        <Button
          className="Card--ApplyButton"
          appearance="dark-blue"
          onClick={() => {
            onPlanSelect();
          }}
        >
          <Trans
            id="pricing_section.plan.trial.button_text"
            values={{ days: trialDays }}
          />
        </Button>
      </SubscriptionBox>
    </Box>
  );
};

export default PricingTrialCard;
